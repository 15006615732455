import "core-js/modules/es.array.push.js";
import config from '@/config';
import Storage from '@/utils/storage';
import { mapGetters, mapMutations } from 'vuex';
import Pagination from '@/components/pagination/Pagination';
import { uploadRecordGetUploadRecordInfo, uploadRecordRemoveByUploadId } from '@/api/salary';
import { formatChinaTime } from '@/utils/util';
export default {
  name: 'PayslipManager',
  components: {
    Pagination
  },
  data() {
    return {
      QY_ADMIN: config.QY_ADMIN,
      tableData: [],
      pages: {
        currentPage: 1,
        // 当前页码
        pageTotal: 0,
        // 数据总条目
        pageSize: 50 // 每页显示多少条数据
      },
      excelData: []
    };
  },
  computed: {
    ...mapGetters(['roleName'])
  },
  mounted() {
    this.fetchDataFn(this.pages);
  },
  methods: {
    ...mapMutations('payslip', ['setUploadRecordId', 'setUploadStep']),
    indexMethod(index) {
      const {
        currentPage,
        pageSize
      } = this.pages;
      return index + 1 + (currentPage - 1) * pageSize;
    },
    formatYearMonthFn({
      year,
      month
    }) {
      return `${year}年${month}月`;
    },
    formatSendTimeFn({
      sendTime
    }) {
      return formatChinaTime(sendTime, 'yyyy-MM-dd HH:mm:ss');
    },
    // 请求工资表格分页数据
    async fetchDataFn(pages) {
      const {
        currentPage: pageNo,
        pageSize
      } = pages;
      const params = {
        pageNo,
        pageSize
      };
      const res = await uploadRecordGetUploadRecordInfo(params);
      if (res !== null && res !== void 0 && res.data) {
        const {
          items = [],
          total = '0'
        } = res.data;
        this.tableData = items;
        this.pages.pageTotal = Number(total);
      }
    },
    // 分页改变
    handleChangePageFn(val) {
      this.$refs.tableRef.bodyWrapper.scrollTop = 0;
      this.pages = {
        ...this.pages,
        ...val
      };
      this.fetchDataFn(this.pages);
    },
    handleRowClick(row) {
      const {
        uploadRecordId: uploadId,
        year,
        month
      } = row;
      // 跳转工资表明细页面
      this.$router.push({
        path: '/salary-detail-list',
        query: {
          uploadId,
          year,
          month
        }
      });
    },
    tableRowClassName() {
      return 'row-hover-table';
    },
    goPageFn(path) {
      this.$router.push(path);
    },
    // 跳转到核对工资表页面
    goCheckPageFn(event, row) {
      // 阻止冒泡
      event.stopPropagation();
      Storage.sessionSet('yearMonth', `${row.year}${row.month}`);
      const {
        uploadRecordId
      } = row;
      // 存uploadId和当前上传步骤
      this.setUploadRecordId(uploadRecordId);
      this.setUploadStep(2);
      this.$router.push({
        path: '/payslip-upload',
        query: {
          manager: 'true'
        }
      });
    },
    handleDeleteRowFn(event, row) {
      // 阻止冒泡
      event.stopPropagation();
      this.$confirm('您确认将删除该条数据吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRecordFn(row.uploadRecordId);
      }).catch(() => {});
    },
    async deleteRecordFn(uploadRecordId) {
      const res = await uploadRecordRemoveByUploadId({
        id: uploadRecordId
      });
      if (res !== null && res !== void 0 && res.data) {
        const msg = this.$message({
          message: '操作成功',
          type: 'success'
        });
        setTimeout(() => {
          msg.close();
          this.fetchDataFn(this.pages);
        }, 800);
      }
    }
  }
};