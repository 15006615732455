import { render, staticRenderFns } from "./PayslipManager.vue?vue&type=template&id=3db4d734&scoped=true"
import script from "./PayslipManager.vue?vue&type=script&lang=js"
export * from "./PayslipManager.vue?vue&type=script&lang=js"
import style0 from "./PayslipManager.vue?vue&type=style&index=0&id=3db4d734&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3db4d734",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/cci-14816-341419/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3db4d734')) {
      api.createRecord('3db4d734', component.options)
    } else {
      api.reload('3db4d734', component.options)
    }
    module.hot.accept("./PayslipManager.vue?vue&type=template&id=3db4d734&scoped=true", function () {
      api.rerender('3db4d734', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/payslip/PayslipManager.vue"
export default component.exports