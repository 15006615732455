var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "payslip-manager table-container"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v("工资表管理")]), _c("div", {
    staticClass: "payslip-btn"
  }, [_c("div", {
    staticClass: "left-btn"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.goPageFn("/payslip-upload");
      }
    }
  }, [_vm._v("Excel发工资条 ")]), _vm.QY_ADMIN === _vm.roleName ? _c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-setting"
    },
    on: {
      click: function ($event) {
        return _vm.goPageFn("/payslip-authority");
      }
    }
  }, [_vm._v("管理权限 ")]) : _vm._e()], 1), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: function ($event) {
        return _vm.fetchDataFn(_vm.pages);
      }
    }
  }, [_vm._v("刷新数据")])], 1), _c("el-table", {
    ref: "tableRef",
    staticClass: "payslip-manager-content",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      height: "100%",
      "row-class-name": _vm.tableRowClassName
    },
    on: {
      "row-click": _vm.handleRowClick
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      width: "50",
      label: "序号",
      align: "center",
      index: _vm.indexMethod
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "year",
      label: "年/月",
      formatter: _vm.formatYearMonthFn,
      "min-width": "100"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "totalSalary",
      label: "发放金额"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "totalSend",
      label: "总人数"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "haveSent",
      label: "已发送"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "sendTime",
      label: "发送时间",
      formatter: _vm.formatSendTimeFn,
      "min-width": "140"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "pushStatus",
      label: "发送状态",
      "min-width": "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row
      }) {
        return [row.pushStatus === 0 ? _c("el-tag", {
          attrs: {
            type: "info"
          }
        }, [_vm._v("未发送")]) : row.pushStatus === 1 ? _c("el-tag", {
          attrs: {
            type: "success"
          }
        }, [_vm._v("发送成功")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "操作",
      "min-width": "135"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row
      }) {
        return [row.pushStatus === 0 ? _c("el-button", {
          staticClass: "edit",
          attrs: {
            type: "text",
            size: "small"
          },
          on: {
            click: function ($event) {
              return _vm.goCheckPageFn($event, row);
            }
          }
        }, [_vm._v("核对工资表")]) : _vm._e(), _c("el-button", {
          staticClass: "danger",
          attrs: {
            type: "text",
            size: "small"
          },
          on: {
            click: function ($event) {
              return _vm.handleDeleteRowFn($event, row);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1), _c("Pagination", {
    attrs: {
      "page-no": _vm.pages.currentPage,
      limit: _vm.pages.pageSize,
      "page-total": _vm.pages.pageTotal
    },
    on: {
      "update:pageNo": function ($event) {
        return _vm.$set(_vm.pages, "currentPage", $event);
      },
      "update:page-no": function ($event) {
        return _vm.$set(_vm.pages, "currentPage", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.pages, "pageSize", $event);
      },
      changePagination: _vm.handleChangePageFn
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };